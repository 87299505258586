import React from 'react';
import { Stack, styled } from '@mui/material';
import { motion } from 'framer-motion';

const MarqueeRow = ({ children, duration, direction }) => (
  <Stack
    component={motion.div}
    sx={{ '& > *:last-child': { marginRight: theme => theme.spacing(3) } }}
    direction='row'
    gap={3}
    animate={{
      x: [direction === 'left' ? '0%' : '-100%', direction === 'left' ? '-100%' : '0%'],
      transition: { repeat: Infinity, duration, ease: 'linear' },
    }}>
    {children}
  </Stack>
);

const Marquee = ({ children, direction = 'left', duration = 40, ...rest }) => (
  <MaskedStack {...rest} direction='row' overflow='hidden'>
    <MarqueeRow duration={duration} direction={direction}>
      {children}
    </MarqueeRow>
    <MarqueeRow duration={duration} direction={direction}>
      {children}
    </MarqueeRow>
  </MaskedStack>
);

export default Marquee;

const MaskedStack = styled(Stack)({
  maskImage:
    'linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 10%, hsl(0 0% 0% / 1) 90%, hsl(0 0% 0% / 0))',
});
