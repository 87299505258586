import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },

  palette: {
    primary: {
      light: '#e4edea',
      main: '#058341',
    },
    secondary: {
      main: '#6EABA9',
    },
    neutral: {
      black: '#313437',
      white: '#F7F8Fc',
      grey: '#718096',
      lightgrey: '#E4E6F0',
    },
  },

  borderRadius: {
    tiny: '.5rem',
    small: '2rem',
    medium: '6rem',
  },

  boxShadow: {
    light: '0 44px 24px rgba(237,239,241,.15)',
    average: '0 .5rem 3rem rgba(0,0,0,0.15)',
  },

  gradient: {
    background: 'linear-gradient(to right bottom, rgb(110, 171, 169, .9), rgb(5, 131, 65, .9))',
  },
});

export default theme;
