import React from 'react';
import { Typography, styled } from '@mui/material';

const Heading = ({ variant = 'primary', children, ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <BaseTypography textAlign='center' variant='h3' {...rest}>
          {children}
        </BaseTypography>
      );

    case 'secondary':
      return (
        <BaseTypography variant='h4' {...rest}>
          {children}
        </BaseTypography>
      );

    case 'tertiary':
      return (
        <BaseTypography color='primary.main' textAlign='center' variant='h4' {...rest}>
          {children}
        </BaseTypography>
      );

    default:
      return (
        <BaseTypography variant='h6' {...rest}>
          {children}
        </BaseTypography>
      );
  }
};

export default Heading;

const BaseTypography = styled(Typography)({
  textTransform: 'capitalize',
});
