import React from 'react';

import Navigation from './navigation';
import Footer from './footer';
import { styled } from '@mui/material';

const Layout = ({ children }) => (
  <>
    <Navigation />
    <StyledMain>{children}</StyledMain>
    <Footer />
  </>
);

export default Layout;

const StyledMain = styled('main')(({ theme }) => ({
  paddingTop: '7rem',

  [theme.breakpoints.down('md')]: {
    paddingTop: '8rem',
  },
}));
