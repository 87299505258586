import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Divider, Link, Stack, Typography, styled } from '@mui/material';

import { Grid, Icon, Section } from 'ui';
import logoImg from 'images/logo-full.png';

const Footer = () => (
  <Section component='footer'>
    <FooterPartition>
      <SocialMediaContainer>
        <FooterLogo src={logoImg} alt='LOGO' />

        <Stack gap={1} alignItems={{ xs: 'center', md: 'flex-start' }} color='grey.600'>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href='https://www.google.com/maps?q=-17.7746702,30.9647405&z=17&hl=en'
            target='_blank'>
            <Icon name='MapPin' color='grey.600' />
            <Typography>Pratapnagar, Vadodara</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href={`tel:${process.env.GATSBY_CONTACT_NUMBER}`}>
            <Icon name='Phone' color='grey.600' />
            <Typography>{process.env.GATSBY_CONTACT_NUMBER}</Typography>
          </Stack>
        </Stack>

        <Stack direction='row' gap={2}>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Instagram' />
          </SocalIcon>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Youtube' />
          </SocalIcon>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Twitter' />
          </SocalIcon>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Linkedin' />
          </SocalIcon>
        </Stack>
      </SocialMediaContainer>

      <Grid
        columns={2}
        color='grey.600'
        justifyItems='center'
        textTransform='capitalize'
        gap={10}
        responsive={false}
        sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
        <Stack gap={2}>
          <Typography variant='h6' color='grey.800' fontWeight={600}>
            Links
          </Typography>

          <GatsbyLink to='/#section-categories'>categories</GatsbyLink>
          <GatsbyLink to='/shop/category/all-products'>products</GatsbyLink>
          <GatsbyLink to='/about'>about us</GatsbyLink>
          <GatsbyLink to='/'>references</GatsbyLink>
        </Stack>

        <Stack gap={2}>
          <Typography variant='h6' color='grey.800' fontWeight={600}>
            help
          </Typography>

          <a href={`mailto:${process.env.GATSBY_EMAIL_ADDRESS}`}>support</a>
          <GatsbyLink to='/about'>about us</GatsbyLink>
          <GatsbyLink to='/#section-faq'>FAQ</GatsbyLink>
          <GatsbyLink to='/privacy-policy'>privacy</GatsbyLink>
        </Stack>
      </Grid>
    </FooterPartition>

    <Divider />

    <FootNote>
      <Typography width={{ xs: '100%', md: '50%' }}>
        &copy; {new Date().getFullYear()} Copyright. All rights reserved.
      </Typography>

      <p>
        Developed by{' '}
        <Link target='_blank' color='inherit' href='https://aminpainter.netlify.app/'>
          Mohammed Amin Painter
        </Link>
        .
      </p>
    </FootNote>
  </Section>
);

export default Footer;

const FooterPartition = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(4),
  gap: theme.spacing(7),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const SocialMediaContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

const FooterLogo = styled('img')({
  width: '15rem',
});

const SocalIcon = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.neutral.lightgrey,
  padding: '.5rem',
  borderRadius: '100%',
  transition: 'transform .3s',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const FootNote = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0),
  color: theme.palette.grey[500],
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));
