import React from 'react';
import { Script } from 'gatsby';
import { ThemeProvider } from '@mui/material';
import { MotionConfig } from 'framer-motion';

import { theme } from 'ui';
import Layout from 'components/layout';
import 'styles/global.css';

const App = ({ children }) => (
  <MotionConfig transition={{ type: 'tween', duration: 0.5 }}>
    <ThemeProvider theme={theme}>
      <Layout>{children}</Layout>
    </ThemeProvider>

    <Script src={process.env.GATSBY_HUBSPOT_CHAT_URL} />
  </MotionConfig>
);

export default App;
