import React from 'react';
import { Box, Container, Typography, styled } from '@mui/material';

import Heading from './heading';

const Section = ({
  children,
  colorish = false,
  title = null,
  subtitle = null,
  containerStyles = {},
  ...rest
}) => (
  <StyledWrapper component='section' {...rest}>
    <StyledContainer colorish={colorish ? 1 : 0} style={containerStyles}>
      {title && (
        <Heading variant='tertiary' mb={subtitle ? 2 : 5}>
          {title}
        </Heading>
      )}

      {subtitle && (
        <Typography textAlign='center' width={{ xs: '100%', md: '60%' }} mx='auto' mb={5}>
          {subtitle}
        </Typography>
      )}

      {children}
    </StyledContainer>
  </StyledWrapper>
);

export default Section;

const StyledWrapper = styled(Box)({
  position: 'relative',
  '&:last-child .MuiContainer-root': {
    paddingBottom: 0,
  },
});

const StyledContainer = styled(Container, { shouldForwardProp: false })(({ theme, colorish }) => ({
  maxWidth: '90vw !important',
  backgroundColor: colorish ? theme.palette.primary.light : theme.palette.neutral.white,
  borderRadius: colorish ? theme.borderRadius.small : 0,
  padding: colorish ? `${theme.spacing(10)} !important` : theme.spacing(15, 0),

  [theme.breakpoints.down('sm')]: {
    padding: colorish ? `${theme.spacing(5, 2.5)} !important` : `${theme.spacing(5, 1)} !important`,
  },
}));
