import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { navigationLinks } from 'utils/data';
import { Button, Icon } from 'ui';
import logoImg from 'images/logo-full.png';

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);

  return (
    <>
      <NavBar>
        <Logo to='/'>
          <img src={logoImg} alt='LOGO' />
        </Logo>
        <LogoSeparator flexItem />

        <NavLinksContainer gap={3} direction='row'>
          {navigationLinks.map((navLink, idx) => (
            <NavLink key={idx} to={navLink.href} activeClassName='nav-link-active'>
              <Typography>{navLink.title}</Typography>
            </NavLink>
          ))}
        </NavLinksContainer>

        <ActionButton
          component={Link}
          to='/enquire'
          endIcon={<Icon name='ArrowUpRight' />}
          className='desktop-action-button'>
          Enquire now
        </ActionButton>

        <Hamburger onClick={toggleMobileNav(true)}>
          <Icon name='Menu' color='neutral.black' />
        </Hamburger>
      </NavBar>

      <Drawer open={openMobileNav} anchor='top' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <Stack alignItems='flex-end' px={2}>
            <IconButton onClick={toggleMobileNav(false)}>
              <Icon name='X' color='primary.main' />
            </IconButton>
          </Stack>

          <List>
            {navigationLinks.map((navLink, idx) => (
              <MobileNavItem key={idx}>
                <Link
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  onClick={toggleMobileNav(false)}>
                  {navLink.title}
                </Link>
              </MobileNavItem>
            ))}
          </List>

          <Stack alignItems='center' mt={1}>
            <ActionButton component={Link} to='/enquire' endIcon={<Icon name='ArrowUpRight' />}>
              Enquire now
            </ActionButton>
          </Stack>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

const NavBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  backgroundColor: 'rgb(247, 248, 252, .75)',
  backdropFilter: 'blur(16px) saturate(180%)',
  boxShadow: '0 1.5rem 3rem -.75rem rgba(0, 0, 0, .25)',
  padding: theme.spacing(2, 4),
}));

const Logo = styled(Link)({
  display: 'flex',

  '& img': {
    height: '3.5rem',
  },
});

const LogoSeparator = styled(Divider)(({ theme }) => ({
  width: '1px',
  height: '1.8rem',
  alignSelf: 'center',
  margin: theme.spacing(0, 3),
  backgroundColor: theme.palette.neutral.grey,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavLinksContainer = styled(Stack)(({ theme }) => ({
  color: theme.palette.neutral.grey,
  textTransform: 'capitalize',
  marginRight: 'auto',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    bottom: '-.4rem',
    transform: 'scaleX(0)',
    transition: 'all .4s',
    transformOrigin: 'left',
    willChange: 'transform',
  },

  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  padding: '.4rem 1.2rem',
  fontSize: '1rem',

  [theme.breakpoints.down('md')]: {
    '&.desktop-action-button': {
      display: 'none',
    },
  },
}));

const Hamburger = styled(IconButton)(({ theme }) => ({
  display: 'none',
  marginLeft: 'auto',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  backgroundColor: theme.palette.neutral.white,
}));

const MobileNavItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
});
